<template>
  <SLoader>
    <div class="widget">
      <div class="widget__header">
        <img
          v-if="type == 'napostu'"
          src="@/assets/img/napostu-logo.png"
          alt="Balík Na Poštu logo"
        />
        <img
          v-else
          src="@/assets/img/balikovna-logo.svg"
          alt="Balíkovna logo"
        />
        <button @click="sendwidgetCloseMessage">&times;</button>
      </div>
      <div class="widget__content">
        <div class="map-wrapper">
          <SMap
            :centerLngLat="[center.lng, center.lat]"
            v-bind:zoom="defaultZoom"
            style="width: 100%; height: 100%"
            @viewport-changed="geBranchesByViewport"
          >
            <SDefaultControls />
            <SMarkerLayer :useCluster="true" @marker-clicked="markerClicked">
              <SMarker
                v-for="m in branchesLoaded"
                :key="m.psc"
                :lngLat="[m.lng, m.lat]"
                :customPinId="m.psc"
              />
            </SMarkerLayer>
            <SMarkerLayer>
              <SMarker
                v-if="selectedBranch"
                :lngLat="[selectedBranch.lng, selectedBranch.lat]"
                iconUrl="/pin2.png"
                :customPinId="currentBranchMarkerId"
              />
            </SMarkerLayer>
          </SMap>
        </div>
        <div class="branchlist-wrapper">
          <BranchList
            :branches="branches"
            @coordinates-updated="updateCoordinates"
            @branch-selected="updateSelectedBranch"
          />
          <BranchDetail
            v-if="branchDetailOpen"
            :branch="selectedBranch"
            @close-branch-detail="closeBranchDetail"
            @final-branch-selected="finalBranchSelected"
          />
        </div>
      </div>
    </div>
  </SLoader>
</template>

<script>
import SLoader from "./components/SMaps/SLoader.vue";
// import SMap from "./components/SMaps/SMap.vue";
import SDefaultControls from "./components/SMaps/SDefaultControls.vue";
import SMarkerLayer from "./components/SMaps/Marker/SMarkerLayer.vue";
import SMarker from "./components/SMaps/Marker/SMarker.vue";
import sphereKnn from "sphere-knn";
import BranchList from "./components/BranchList.vue";
import BranchDetail from "./components/BranchDetail.vue";

import { defineAsyncComponent } from "vue";

import _ from "lodash";

const CURRENT_BRANCH_MARKER_ID = "currentBranchMarker";

const sortByDistance = (items, lng, lat) => {
  let lookup = sphereKnn(items);

  let maxResults = 3000;
  let maxDistance = 99999999; // [m]
  return lookup(lat, lng, maxResults, maxDistance);
};

export default {
  name: "App",
  components: {
    SLoader,
    SMap: defineAsyncComponent(() => import("./components/SMaps/SMap.vue")),
    // SMap,
    SDefaultControls,
    SMarkerLayer,
    SMarker,
    BranchList,
    BranchDetail,
  },
  data() {
    return {
      type: "balikovna",
      branches: [],
      branchesNotLoaded: [],
      branchesLoaded: [],
      defaultZoom: 14,
      center: { lng: 14.4341412988, lat: 50.0835493857 },
      selectedBranch: null,
      branchDetailOpen: false,
      currentBranchMarkerId: CURRENT_BRANCH_MARKER_ID,
    };
  },
  mounted() {
    // Get type from query param type = ['balikovna', 'napostu']
    const params = new URLSearchParams(window.location.search);
    const type = params.get("type");
    this.type = type ? type : this.type;

    let url = "/balikovna.json";
    // let url = "https://dev.barberco.cz/uploads/shipping/balikovna.json";

    fetch(url, { mode: "no-cors" })
      .then((res) => res.json())
      .then((out) => {
        if (type == "napostu") {
          out = _.filter(out, (b) => {
            return b.typ == "pošta";
          });
        }

        this.branches = out;
        this.branchesNotLoaded = out;

        this.branches = sortByDistance(
          this.branches,
          this.center.lng,
          this.center.lat
        );

        this.branchesLoaded = _.slice(this.branchesNotLoaded, 0, 200);
        this.branchesNotLoaded = _.slice(this.branchesNotLoaded, 200);
      })
      .catch((err) => {
        throw err;
      });
  },
  methods: {
    updateCoordinates(coordinates) {
      this.center.lng = coordinates.lng;
      this.center.lat = coordinates.lat;

      this.branches = sortByDistance(
        this.branches,
        this.center.lng,
        this.center.lat
      );
    },
    updateSelectedBranch(branch) {
      this.selectedBranch = branch;

      this.center.lng = branch.lng;
      this.center.lat = branch.lat;

      this.branchDetailOpen = true;
    },
    markerClicked(markerId) {
      if (markerId === CURRENT_BRANCH_MARKER_ID) {
        this.branchDetailOpen = true;
        return;
      }
      this.selectedPsc = markerId;
      let found = _.find(this.branches, { psc: markerId });

      if (found) this.updateSelectedBranch(found);
    },
    geBranchesByViewport(viewport) {
      let filtered = _.remove(this.branchesNotLoaded, function(b) {
        return (
          b.lng > viewport.leftBottom.lng &&
          b.lng < viewport.rightTop.lng &&
          b.lat > viewport.leftBottom.lat &&
          b.lat < viewport.rightTop.lat
        );
      });

      this.branchesLoaded = _.concat(this.branchesLoaded, filtered);
    },
    closeBranchDetail() {
      this.branchDetailOpen = false;
      // this.selectedBranch = null;
    },
    finalBranchSelected(branch) {
      console.log("finalBranchSelected", branch);

      const message = JSON.stringify({
        psc: branch.psc,
        nazev: branch.nazev,
        type: this.type,
      });
      window.parent.postMessage(message, "*");
    },
    sendwidgetCloseMessage() {
      window.parent.postMessage(null, "*");
    },
  },
};
</script>

<style>
* {
  --blue-dark: #002776;
  --blue-light: #00acdc;
  --grey: #2c3e50;
}

a,
a:visited,
.btn-link {
  color: #0043c9;
  text-decoration: underline;
}

a:hover,
.btn-link:hover {
  text-decoration: none;
}

html,
body {
  font-size: 12px;
}

input {
  box-sizing: border-box;
}

button {
  cursor: pointer;

  border: none;
  background-color: transparent;
}
button:focus {
  outline: none;
}

h1 {
  font-size: 1.5rem;
  color: var(--blue-dark);
}

h4 {
  margin-bottom: 0.7em;
  color: #002776;
}

#app {
  font-family: "Open Sans", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: var(--grey);
  /* margin-top: 60px; */
}

.widget {
  width: 100%;
  max-width: 900px;
  max-height: 83vh;
  margin: 0 auto;
  box-shadow: 0 0.4rem 1.5rem rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-content: stretch;
  background-color: #fff;
}

.widget__header {
  display: flex;
  justify-content: space-between;
  flex: 0 1 auto;
  border-bottom: 1px solid #eee;
  /* background-color: var(--blue-light); */
}
.widget__header img {
  max-height: 3rem;
}
.widget__header button {
  font-size: 2rem;
  color: var(--grey);
}

.widget__content {
  display: flex;
  flex-direction: row;
  width: 100%;

  flex: 1 1 auto;
  overflow: hidden;

  box-sizing: border-box;
}

.branchlist-wrapper {
  flex: 1;
  position: relative;
}

.map-wrapper {
  flex: 2;
}

@media (max-width: 600px) {
  .map-wrapper {
    flex: 1;
  }
}
@media (max-width: 501px) {
  .widget__content {
    flex-direction: column;
  }
  .map-wrapper {
    height: 90px;
    flex: 0 1 90px;
  }
  .branchlist-wrapper {
    flex: 1 1 auto;
    overflow: hidden;
  }
}
</style>
