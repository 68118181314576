<template>
  <slot></slot>
</template>

<script>
import { inject, provide, onMounted, onBeforeUpdate, ref, nextTick } from "vue";

export default {
  name: "SMarkerLayer",
  props: {
    useCluster: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  emits: ["markerClicked"],
  setup(props, { emit }) {
    let markers = []; // Stores markers to be added to layer (=> map)

    const SMap = inject("SMap").value;
    const map = inject("map").value;

    let layer = ref(new SMap.Layer.Marker());

    if (props.useCluster) {
      let clusterer = new SMap.Marker.Clusterer(map);
      layer.value.setClusterer(clusterer);
    }

    provide("layer", layer);
    provide("addMarker", (marker) => {
      markers.push(marker);
    });

    map.getSignals().addListener(this, "marker-click", function(e) {
      // Marker that has been clicked
      let marker = e.target;
      // marker.setURL("/pin2.png");

      emit("markerClicked", marker.getId());
    });

    const addAllMarkersToMap = async () => {
      await nextTick();

      layer.value.addMarker(markers);
      markers = []; // prevent adding marker more than once (when onBeforeUpdate is called)
    };

    onMounted(() => {
      map.addLayer(layer.value).enable();
      addAllMarkersToMap();
    });
    onBeforeUpdate(() => {
      addAllMarkersToMap();
    });
  },
};
</script>
