<script>
import { inject, ref, watch, onMounted, onBeforeUpdate } from "vue";

const getIconUrl = (props) => {
  return props.iconUrl ? props.iconUrl : "/pin.png";
};

export default {
  name: "SMarker",
  props: {
    lngLat: {
      type: [Object, Array],
      default: null,
      required: true,
    },
    iconUrl: {
      type: String,
      default: null,
    },
    customPinId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    let marker = null;
    let active = ref(false);

    const addMarker = inject("addMarker");
    let SMap = inject("SMap").value;

    let markerIcon = ref(getIconUrl(props));

    onMounted(() => {
      SMap = inject("SMap").value;

      let coords = SMap.Coords.fromWGS84(props.lngLat[0], props.lngLat[1]);
      marker = new SMap.Marker(coords, props.customPinId, {
        url: markerIcon.value,
        anchor: { left: 16, bottom: 0 },
      });
      addMarker(marker);
      active.value = true;
    });

    onBeforeUpdate(() => {
      if (!active.value) {
        addMarker(marker);
        active.value = true;
      }
    });

    watch(
      () => props.lngLat,
      (to, from) => {
        if (from[0] !== to[0] || from[1] !== to[1]) {
          let coords = SMap.Coords.fromWGS84(to[0], to[1]);
          marker.setCoords(coords);
        }
      }
    );
    watch(
      () => props.iconUrl,
      (to, from) => {
        // @TODO: Test marker icon's reactivity
        console.log("micon", from, to);
        markerIcon.value = getIconUrl(to);
        marker.setURL(markerIcon.value);
      }
    );

    return { marker };
  },
  render() {
    return null;
  },
};
</script>
